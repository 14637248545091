
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url('https://fonts.googleapis.com/css?family=Lato');

// Variables
@import "variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/* Fonts/Text
********************************************************/
body, h1, h2, h3, h4, h5, h6, p, input, select, div, a {
    font-family: Lato, helvetica, sans-serif;
}


/* General
********************************************************/
.center-align {
    text-align: center;
}


/* Alerts
********************************************************/
.alert {
    border: 3px solid #eee;
    background: #f1f1f1;
    padding: 15px;
    line-height: 1em;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    
    p {
        font-size: 1em;
        margin-bottom: 0;
    }
    
    &.alert--success {
        background: #d0ffd7;
        border: 3px solid #9cffa8;
    }
    
    &.alert--error {
        background: #ffd0d0;
        border: 3px solid #ff9c9c;
    }
}


/* Homepage
********************************************************/
.customisation-types {
    margin: 2em 0 5em;
    
    .customisation-type {
        margin: 1em 0;
        padding: 1em;
        border: 2px solid #ddd;
        background: #e4e4e4;
        font-size: 1.6em;
        display: block;
        
        .fas {
            font-size: 5em;
        }
        
        :hover {
            cursor: pointer;
        }
    }
}


/* Designer
********************************************************/
#previewcontainer {
    color: #f1efa5;
    margin-left: auto;
    margin-right: auto;
    display: none;
    max-width: 100%;
    width: 100%;
    margin-top: 3em;
    margin-bottom: 3em;
    background: url('/images/products/blacktamper.jpg') no-repeat center center;
    background-size: cover;
}
#previewcontainer p {
    margin: 0;
    padding: 0;
}
#preview {
    vertical-align: bottom;
    max-width: 100%;
    outline: none !important;
    border: 0 !important;
    background: none;
    padding: 90px;
    color: #fff;
    opacity: 0.8;
}
#go {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 250px;
    clear: both;
}
.noUiSlider.horizontal {
    width: 250px;
    margin-top: 5px;
}
.slidercontainer {
    margin: 0 auto;
    width: 250px;
    display: block;
    clear: both;
}

.variants {
    margin: 2em 0;
    
    .swatches {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: left;
        
        .swatch {
            display: inline-block;
            width: 41px;
            height: 41px;
            border-radius: 41px;
            margin: 5px;
            padding: 3px;
            border: 1px solid #ccc;
            
            span {
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 41px;
                
                &:hover {
                    cursor: pointer;
                }
            }
            
            &:hover, &.selected {
                cursor: pointer;
                opacity: 0.9;
                border: 1px solid #000;
            }
        }
    }
}

.js-change-quantity {
    &:hover {
        cursor: pointer;
    }
}


@media (max-width: 767px) {
    .skin-red {
        .main-header {
            .navbar {
                .dropdown-menu {
                    li {
                        a:hover {
                            background: $brand-secondary;
                        }
                    }
                }
            }
        }
    }
}